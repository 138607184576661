<template>
  <div class="members">
    <h1>Members</h1>
    <p>List of our members.</p>

    <div class="members-list">
      <div class="member-card" v-for="member in members" :key="member.email">
        <img :src="member.photo" alt="Member Photo" class="member-photo" />
        <h2>{{ member.name }}</h2>
        <p><strong>Profession:</strong> {{ member.profession }}</p>
        <p><strong>Email:</strong> {{ member.email }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MembersPage',
  data() {
    return {
      members: [
        {
          name: 'Sathish Ravi',
          profession: 'Simulations Engineer',
          email: 'zzz@example.com',
          photo: require('./sathish_Anna.jpeg') 
        },
        {
          name: 'Marsook Rahman',
          profession: 'Requirements Engineer',
          email: 'zzz@example.com',
          photo: require('./marsook_anna.jpeg') 
        },
        {
          name: 'Anishreddy Saravanan',
          profession: 'Design Engineer',
          email: 'zzz@example.com',
          photo: require('./anish.jpeg') 
        },
        {
          name: 'Balakumar Vairavan',
          profession: 'Application Engineer',
          email: 'zzz@example.com',
          photo: require('./bala.jpeg') 
        }
      ],
    };
  },
};
</script>

<style scoped>
.members {
  padding: 20px;
  text-align: center; /* Center the text */
}

.members-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 20px; /* Space between cards */
}

.member-card {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  width: 200px; /* Set width for each card */
  text-align: center; /* Center text inside cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.member-photo {
  width: 100%; /* Set to 100% to fit the card */
  border-radius: 50%; /* Make the photo round */
  max-width: 100px; /* Limit max width */
  margin-bottom: 10px; /* Space below the photo */
}
</style>
