<!-- src/views/HomePage.vue -->

<template>
  <div class="home">
    <h1>Welcome to Tamil Sangam</h1>
    <p style="font-size: large; margin-bottom: 40px;">Graz Tamil Sangham is a registered association for the Tamil community residing in Graz. The association's primary purpose is to bring people together through gatherings and celebrations on special occasions. Additionally, it organizes Tamil language classes for children living in Graz, helping to preserve and promote Tamil culture and language among the younger generation.</p>

    <!-- Carousel with imported images -->
    <div class="carousel">
      <div class="carousel-images" :style="{ transform: `translateX(-${activeIndex * 100}%)` }">
        <img v-for="(image, index) in images" :key="index" :src="image" alt="Carousel Image" class="carousel-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      activeIndex: 0, // To track which image is currently shown
      images: [
        require('./image1.png'),
        require('./image2.jpeg'),
        require('./image3.jpeg')
      ],
      interval: null // Variable to store the interval ID
    };
  },
  methods: {
    nextImage() {
      // Automatically change to the next image
      this.activeIndex = (this.activeIndex + 1) % this.images.length;
    },
    startCarousel() {
      // Start the carousel with an interval to change the image every 3 seconds
      this.interval = setInterval(this.nextImage, 3000);
    },
    stopCarousel() {
      // Stop the carousel when the component is destroyed
      clearInterval(this.interval);
    }
  },
  mounted() {
    // Start the carousel when the component is mounted
    this.startCarousel();
  },
  beforeUnmount() {
    // Clean up the interval when the component is destroyed
    this.stopCarousel();
  }
};
</script>

<style scoped>
.home {
  padding: 20px;
  text-align: center; /* Center the text */
}

.carousel {
  position: relative;
  margin: 20px auto; /* Center the carousel */
  width: 70%; /* Adjust as needed */
  overflow: hidden; /* Hide overflow for a clean look */
}

.carousel-images {
  display: flex; /* Use flexbox for horizontal alignment */
  transition: transform 0.5s ease; /* Smooth transition for sliding effect */
}

.carousel-image {
  width: 100%; /* Adjust to fit your layout */
  max-width: calc(100% * 5); /* Set max-width for images */
  margin: 0 auto; /* Center images */
}
</style>
