<!-- src/views/Events.vue -->

<template>
  <div class="events">
    <h1>Upcoming Events</h1>
    <p>Check out our upcoming events!</p>

    <!-- Event badges -->
    <div class="event-badges">
      <div
        v-for="(event, index) in events"
        :key="index"
        class="event-badge"
        @click="openModal(index)"
      >
        <h3>{{ event.title }}</h3>
        <span>Click for details</span>
      </div>
    </div>

    <!-- Event Details Modal -->
    <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h2>{{ selectedEvent.title }}</h2>
      <p><strong>Date:</strong> {{ selectedEvent.date }}</p>
      <p><strong>Location:</strong> {{ selectedEvent.location }}</p>

      <!-- Container for the image and map -->
      <div class="image-map-container">
        <img :src="selectedEvent.image" alt="Event Image" class="event-image" />
        <div class="map-container">
          <iframe
            :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDaDX8IF0oLYqV6iZ6nLadwS68THGINIsc&q=' + selectedEvent.latitude + ',' + selectedEvent.longitude"
            width="100%"
            height="300"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'EventsPage',
  data() {
    return {
      showModal: false, // To control modal visibility
      selectedEvent: null, // To store the selected event details
      events: [
        {
          title: 'Diwali Celebration',
          date: '02nd November 2024',
          location: 'Marktgemeinde Unterpremstätten - Kultursaal',
          latitude: 46.9705489, 
          longitude: 15.3971957,
          image: require('./diwali_invitation.jpeg')
        },
        {
          title: 'Tamil New Year',
          date: 'TBD',
          location: 'TBD',
          //latitude: 47.0716,
          //longitude: 15.4387,
          image: require('./TBD.png')
        }
      ]
    };
  },
  methods: {
    openModal(index) {
      this.selectedEvent = this.events[index];
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
.events {
  text-align: center;
  padding: 20px;
}

.event-badges {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.event-badge {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.event-badge:hover {
  background-color: #e0e0e0;
  transform: translateY(-5px);
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 900px;
  text-align: center;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.image-map-container {
  display: flex;
  justify-content: space-between; /* Distribute space between image and map */
  align-items: center;
  gap: 20px; /* Space between image and map */
  margin-top: 20px;
}

.event-image {
  width: 45%; /* Adjust width of the image */
  border-radius: 8px;
}

.map-container {
  width: 45%; /* Adjust width of the map */
}

iframe {
  width: 100%;
  height: 300px;
  border: none;
  border-radius: 8px;
}

/* Flexbox to align image and map side by side */
.modal-body {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

.event-image {
  width: 50%; /* Take half the modal width */
  border-radius: 8px;
}

.map-container {
  width: 50%; /* Take half the modal width */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .modal-body {
    flex-direction: column;
    align-items: center;
  }

  .event-image, .map-container {
    width: 100%; /* Full width on smaller screens */
  }
}
</style>

