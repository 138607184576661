<template>
    <div class="about-us">
      <h1>Contact Us</h1>
      <p>Information about our organization.</p>
  
      <!-- Contact Card -->
      <div class="contact-card">
        <h2>Contact Information</h2>
        <p><strong>Address:</strong> Reininghausstrasse 10/28, 8020 Graz, Austria</p>
        <p><strong>Phone:</strong> +43 688 64324717</p>
        <p><strong>Email:</strong> info@graztamilsangam.org</p>
      </div>
  
      <!-- Map with location pin -->
      <div class="map-container">
        <iframe
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDaDX8IF0oLYqV6iZ6nLadwS68THGINIsc&q=Reininghausstraße+10%2F28,+8020+Graz/@47.065952,15.4057578"
          width="100%"
          height="300"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutUs',
  };
  </script>
  
  <style scoped>
  .about-us {
    padding: 20px;
    text-align: center; /* Center the text */
  }
  
  .contact-card {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    margin: 20px auto;
    width: 80%; /* Adjust as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .map-container {
    margin-top: 20px;
    width: 100%;
    max-width: 600px; /* Set a max width for the map */
    margin: 20px auto; /* Center the map */
  }
  </style>
  