<!-- src/components/AppHeader.vue -->
<template>
    <header class="header">
      <div class="header-container">
        <div class="header-title">
          <h1>கிராஸ் தமிழ்ச் சங்கம்</h1>
          <img alt="GTS Logo" src="./GTS.png" class="logo"/>
          <h1>Graz Tamil Sangham</h1>
        </div>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: 'AppHeader'
  }
  </script>
  
  <style scoped>
  .header {
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;;
  }
  
  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header-title {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 60px;
    margin: 0 15px;
  }
  
  .header-nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .header-nav ul li {
    margin-right: 20px;
  }
  
  .header-nav ul li a {
    color: #000000;
    text-decoration: none;
    font-size: 18px;
    padding: 10px 15px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .header-nav ul li a:hover {
    background-color: #f0f0f0;
    color: #007bff;
    border-radius: 5px;
  }
  
  .header-nav ul li a.router-link-exact-active {
    font-weight: bold;
    text-decoration: underline;
  }
  </style>
  