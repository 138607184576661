<template>
  <div id="layout">
    <!-- Use the AppSidebar component in the layout --> 
    
    <!-- Main content area where the router will load different pages -->
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLayout'
}
</script>

<style scoped>
#layout {
  display: flex;
  height: 100vh;
  width: auto;
}

.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 35px;
}
</style>
