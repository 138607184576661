// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router'
import AppLayout from '../components/AppLayout.vue'
import HomePage from '../views/HomePage.vue'
import EventsPage from '../views/EventsPage.vue'
import AboutUs from '../views/AboutUs.vue'
import MembersPage from '../views/MembersPage.vue'

const routes = [
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'HomePage',
        component: HomePage
      },
      {
        path: 'events',
        name: 'EventsPage',
        component: EventsPage
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        component: AboutUs
      },
      {
        path: 'members',
        name: 'MembersPage',
        component: MembersPage
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
