<!-- src/components/Sidebar.vue -->

<template>
  <div class="sidebar">
    <img alt="GTS logo" src="./GTS.png" style="height: 140px; margin-left: 60px; margin-top: 10px; margin-bottom: 60px;">
    <nav>
      <ul>
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/events">Events</router-link>
        </li>
        <li>
          <router-link to="/about-us">About Us</router-link>
        </li>
        <li>
          <router-link to="/members">Members</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'AppSidebar'
}
</script>

<style scoped>
.sidebar {
  width: 300px;
  background-color: #ffffff;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  color: #000000;
  border-right: 4px solid #000000;
}

.sidebar h2 {
  margin-bottom: 30px;
  text-align: center;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
}

.sidebar nav ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 18px;
  display: block;
  padding: 10px 15px; /* Add padding for better click area */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.sidebar nav ul li a:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
  color: #007bff; /* Change text color to blue on hover */
  border-radius: 5px; /* Optional: rounded corners on hover */
}

.sidebar nav ul li {
  margin-bottom: 20px;
}

.sidebar nav ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 18px;
}

.sidebar nav ul li a.router-link-exact-active {
  font-weight: bold;
  text-decoration: underline;
}
</style>
