<template>
  <div id="app">
    <!-- Sidebar Component -->
    <AppSidebar />
    <!-- Main Content Section -->
    <div class="main-content">
      <AppHeader />
      <router-view />
    </div>
  </div>
</template>

<script>
// Import the Sidebar component
import AppSidebar from './components/AppSidebar.vue'
import AppHeader from './components/AppHeader.vue'

export default {
  name: 'App',
  components: {
    AppSidebar,
    AppHeader  
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  width: 100%;
}
</style>
